@if (redirectsToUrl) {
  <!--case we want to go to external website on a new tab-->
  <a data-cy="admin-doc-card-folder-view"
     [href]="url" target="_blank">
    <ng-container *ngTemplateOutlet="cardComponentBody"></ng-container>
  </a>
} @else {
  <!--case we want to stay in explain app-->
    <!--middle click is for the mouse wheel click-->
  <a data-cy="admin-doc-card-folder-view"
     [routerLink]="getRouterLink()"
     (contextmenu)="onClickCard()"
     (click)="onClickCard()"
     (middleclick)="onClickCard()"
     [state]="{context: text}">
    <ng-container *ngTemplateOutlet="cardComponentBody"></ng-container>
  </a>
}

<!--main body of the card-->
<ng-template #cardComponentBody>
  <div [ngClass]="{'preview': isPreview}" class="card-board">
    <div class="d-flex first-line">

      @if (displayHelper) {
        <div class="card-info d-flex">
          <!--macro catégorie-->
          <app-icon-text-badge
            class=" d-flex align-self-center"
            [text]="displayHelper?.text ?? ''"
            [iconName]="displayHelper?.icon_name ?? ''"
          ></app-icon-text-badge>

          <!--separation-->
          <div class="category-separation d-flex align-self-center">
            <mat-icon class="fill-basic-600 icon-16 me-1"
                      svgIcon="chevron-right-basic-600"></mat-icon>
          </div>

          <!--micro catégorie-->
          <div class="publication-category d-flex align-self-center">
            @if (displayedPublicationType !== DisplayedPublicationType.NON_DETECTED) {
              <mat-icon class="fill-basic-800 icon-16 me-1" svgIcon="docs-basic-600"></mat-icon>
            } @else {
              <mat-icon svgIcon="docs-basic-600" class="icon-16 me-1"></mat-icon>
            }

            <div
              [ngClass]="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED) ? 'color-basic-600' : 'color-basic-800'"
              [style.font-style]="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED) ? 'italic' : 'normal'"
              class="category-name fw-600">{{ displayedPublicationType }}
            </div>
          </div>
        </div>
      }

      <div class="occurrence-and-folder d-flex justify-content-end position-static">
        <!--date de publication-->
        <div [ngClass]="{'fw-600': isPreview,
                         'fw-700': !isPreview}"
             class="publication-date d-flex align-self-center">
          <mat-icon svgIcon="calendar-basic-600" class="icon-16 me-1"></mat-icon>
          @if (publicationDate) {
            {{ publicationDate | date:'dd MMMM yyyy':'':'fr' }}
          } @else {
            <div class="text-capitalize">{{ 'content.date_not_detected' | translate }}</div>
          }
        </div>
      </div>
      <!--PREVIEW Website-->
      @if (isPreview) {
        <div class="d-flex color-basic-800 fw-600 align-self-center website-part">
          <mat-icon svgIcon="website" class="icon-16 fill-basic-600 me-1"></mat-icon>
          <div class="text-ellipsis">{{ website }}</div>
        </div>
      }

    </div>

    <div class="second-line">
      <!--title part-->
      <div class="left-part d-flex text-truncate">
        <app-occurrence-and-folder-icon-badge
          class="d-flex"
          [folderId]="folderId"
          [nbOccurrences]="nbOccurrences"
          [showOccurrences]="false"
          [showCreationDate]="false"
          [bookmarkPaths]="bookmarkPaths"
          [showFolder]="true">
        </app-occurrence-and-folder-icon-badge>
        <div class="card-title d-table-cell align-middle position-static text-truncate"
             data-cy="admin-doc-search-card-board-title"
             (mouseenter)="displayExtLinkIcon = true"
             (mouseleave)="displayExtLinkIcon = false"
             [innerHTML]="title">
        </div>
        @if (!isPreview && redirectsToUrl) {
          <!--external link icon in case of redirection to external URL-->
          <mat-icon class="ext-link-icon d-flex align-middle justify-content-center align-content-center"
                    [style.opacity]="redirectsToUrl ? (displayExtLinkIcon ? '1' : '0') : '0'"
                    svgIcon="ext-link-primary-500"></mat-icon>
        }

      </div>
      <!--occurrence count part-->
      <div class="right-part d-table h-100">
        <app-occurrence-and-folder-icon-badge
          class="d-table-cell align-middle"
          [folderId]="folderId"
          [nbOccurrences]="nbOccurrences"
          [showOccurrences]="showOccurrences"
          [bookmarkPaths]="bookmarkPaths"
          [showFolder]="false">
        </app-occurrence-and-folder-icon-badge>
      </div>
    </div>

    <!--IF territories-->
    @if (!isPreview && territories && territories.length > 0) {
      <div class="territory-section"
           [ngClass]="displayReadMore ? 'split' : ''"
           id="territory-section-{{docId}}">
        @if (territories.length > 0) {
          <div class="territories d-flex align-self-center">
            <mat-icon svgIcon="pin-basic-600" class="icon-16 me-1"></mat-icon>
            <div id="territory-list-{{docId}}" class="territory-list text-truncate-territories">
              <span *ngFor="let territory of territories; let i = index"
                    [ngClass]="{'color-primary-500': isPreview, 'color-basic-800' : !isPreview}">
                @if (territory.uid.startsWith(TerritoryKind.REGION)) {
                  <span class="territory-name fw-bold">{{ "content.region" | translate }} </span>
                }
                <span class="territory-name fw-bold">{{ territory.name }}</span>
                @if (territory.uid.startsWith(TerritoryKind.DEPARTMENT)) {
                  <span class="territory-name fw-bold"> ({{territory.code}})</span>
                }
                @if (i < territories.length - 1) {
                  <span class="separator color-basic-600 fw-light"> | </span>
                } @else {
                  <!--last territory of the list-->
                  <span class="fantom-space">...</span>
                }
              </span>
            </div>
          </div>
        }

        @if (displayReadMore) {
          <div class="fading-color-box">
          </div>

          <div id="read-more-{{docId}}"
               class="read-more d-flex"
               (click)="onReadMoreClick($event)"
               [ngbTooltip]="territoryTooltip"
               tooltipClass="custom-tooltip-article-card-territories"
               placement="bottom-right">
            <mat-icon svgIcon="plus-circle-icon-fill"
                      class="icon-16 fill-basic-600 me-1 align-self-center align-middle"></mat-icon>
            <div class="text color-basic-600 fw-bold align-self-center">{{ "content.see-more"| translate }}</div>
          </div>
        }

      </div>
    }

    <!--PREVIEW orange publication type-->
    @if (isPreview) {
      <div class="publication-category-preview d-flex align-self-center">
        @if (displayedPublicationType !== DisplayedPublicationType.NON_DETECTED) {
          <mat-icon class="fill-primary-500 icon-16 me-1" svgIcon="docs-primary-500"></mat-icon>
        } @else {
          <mat-icon svgIcon="docs-basic-600" class="icon-16 me-1"></mat-icon>
        }
        <div
          [ngClass]="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED) ? 'color-basic-600' : 'color-primary-500'"
          [style.font-style]="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED) ? 'italic' : 'normal'"
          class="category-name fw-600">{{ displayedPublicationType }}
        </div>
      </div>
    }

    @if (text) {
      <div class="card-text" data-cy="admin-doc-search-card-board-text" [innerHTML]="text"></div>
    } @else {
      <div class="card-text fst-italic color-basic-600"
           data-cy="admin-doc-search-card-board-text">{{ 'content.extract-not-detected' | translate }}
      </div>
    }

  </div>
</ng-template>

<!--tooltip containing long list of territories when taking more than 1 line-->
<ng-template #territoryTooltip class="territory-tooltip">
      <span *ngFor="let territory of territories; let i = index">
            <span class="territory-name fw-bold color-white">{{ territory['name'] }}</span>
        @if (i < territories.length - 1) {
          <span class="separator color-basic-600 fw-light"> | </span>
        }

      </span>
</ng-template>


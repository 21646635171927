.carousel {
  outline: none !important;
}

.carousel-container {
  height: calc(var(--size-image-carousel) + 64px + 12px);
  padding-bottom: 16px;
  box-sizing: content-box;
  overflow: hidden;

  .carousel-inner {
    overflow: visible;
  }

  .image-container {
    height: var(--size-image-carousel);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .carousel-image {
      cursor: zoom-in;
      max-height: var(--size-image-carousel);
    }
  }

  .carousel-caption {
    width: 100%;
    height: 64px;
    overflow: auto;
    display: flex;
    justify-content: center;
    right: unset;
    left: unset;
    bottom: unset;
    color: unset;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-inline: 8px;
    text-align: justify;

    span {
      max-height: 48px;
      overflow: auto;
      color: var(--basic-800);
      font-size: 12px;
      font-weight: bold;
      font-style: italic;
      line-height: 16px;
    }
  }

  &.one-image {
    height: unset;
    min-height: var(--size-image-carousel);

    .carousel-caption {
      height: unset;
      max-height: 64px;
      position: relative;
      padding-top: 8px;
      padding-bottom: 0;
    }

    &.dialog {
      .carousel-caption {
        position: absolute;
        bottom: -84px;
        height: 64px;
      }

    }
  }

  &.dialog {
    position: fixed;
    height: 100vh;
    width: 100vw;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(2px) contrast(40%);
    z-index: 1200;
    padding-block: 0;

    .icon-button {
      display: block;
    }

    .image-container {
      height: calc(100vh - 84px);
      max-width: calc(100vw - 32px);
      margin: auto;

      .carousel-image {
        max-height: calc(100vh - 100px);
        max-width: calc(100vw - 32px);
        cursor: auto;
      }
    }

    .carousel-caption {
      background-color: var(--basic-100);
    }

    .carousel-indicators {
      margin-inline: 0;
      bottom: -84px;
      background-color: var(--basic-100);
      padding-bottom: 12px;
    }

  }
}

.icon-button {
  display: none;
  border: none;
  outline: none;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 40px;
  height: 40px;
  padding: 0;
  z-index: 1;

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    outline: none;
  }
}

.carousel-control-next-icon {
  height: 40px;
  width: 40px;
  background-image: url(/assets/images/27-icon-arrow-circle-right-fill.svg);
  background-color: var(--basic-100);
  border-radius: 50%;
}

.carousel-control-next {
  width: 15%;
  opacity: 1;
  right: 8px;
  justify-content: flex-end;
}

.carousel-control-prev-icon {
  height: 40px;
  width: 40px;
  background-image: url(/assets/images/27-icon-arrow-circle-left-fill.svg);
  background-color: var(--basic-100);
  border-radius: 50%;
}

.carousel-control-prev {
  width: 15%;
  opacity: 1;
  left: 8px;
  justify-content: flex-start;
}

.carousel-indicators {
  align-items: center;
  margin-bottom: 0;
  bottom: -76px;
}

.carousel-indicators li {
  width: 8px !important;
  background-color: var(--basic-600);
  height: 8px !important;
  border-radius: 50% !important;
  margin-inline: 4px;
  border-block: 0;
}

.carousel-indicators .active {
  width: 12px !important;
  background-color: var(--primary-500);
  height: 12px !important;
  border-radius: 50% !important;
  margin-inline: 2px;
}

import { Injectable } from '@angular/core';
import { TerritoryWatchIssuerKindEnum, TerritoryWatchProjectPublicationTypeEnum, TerritoryWatchSettings } from '../../models/territory-watch-settings';
import { AdminDocSourceIssuerGroupData } from '../../../assets/data/admin-doc-source-data';
import { TreeviewItem } from '../../lib/ngx-treeview';
import {
  prefAndMraeTreeviewItems_adminDocSearch
} from '../../components/source-step-template/source-treeview-items-data';
import { IssuerKindEnum } from '../../models/issuers';

interface TreeviewGroupRepresentative {
  name: string;
  checked: boolean;
  treeviewItems: Array<TreeviewItem>;
}

@Injectable({
  providedIn: 'root'
})
export class IssuerMethodsService {

  sections = ['articles', 'admin-docs'];

  constructor() {
  }

  getIssuerKind(territoryWatchSettings: TerritoryWatchSettings): TerritoryWatchIssuerKindEnum[] {
    const issuerKind: any[] = [];
    for (const section of this.sections) {
      for (const issuerGroup of territoryWatchSettings.sourceStepSections.get(section)?.issuerGroups?.values() || []) {
        this.buildIssuerKindFromIssuerGroup(issuerGroup, issuerKind);
      }
    }
    return issuerKind;
  }

  getIssuerKindFrom(sourceStepSection: Map<string, AdminDocSourceIssuerGroupData>): TerritoryWatchIssuerKindEnum[] {
    const issuerKind: any[] = [];
    for (const issuerGroup of sourceStepSection.values() || []) {
      this.buildIssuerKindFromIssuerGroup(issuerGroup, issuerKind);
    }
    return issuerKind;
  }

  buildIssuerKindFromIssuerGroup(issuerGroup: TreeviewGroupRepresentative, issuerKind: Array<any>) {
    if (issuerGroup.checked) {
      switch (issuerGroup.name) {
        case IssuerKindEnum.press:
          issuerKind.push(TerritoryWatchIssuerKindEnum.PRESS);
          break;
        case IssuerKindEnum.syndicates:
          issuerKind.push(TerritoryWatchIssuerKindEnum.SYNDICAT);
          break;
        case IssuerKindEnum.comm_and_epci:
          issuerGroup.treeviewItems[0]?.children.forEach((item) => {
            if (item.checked) {
              issuerKind.push(item.value);
            }
          });
          break;
        case IssuerKindEnum.region_and_department:
          issuerGroup.treeviewItems[0]?.children.forEach((item) => {
            if (item.checked) {
              issuerKind.push(item.value);
            }
          });
          break;
        case IssuerKindEnum.pref_and_mrae:
          issuerGroup.treeviewItems[0]?.children.forEach((item) => {
            if (item.checked || item.indeterminate) {
              if (item.value === TerritoryWatchProjectPublicationTypeEnum.AVIS_MRAE) {
                issuerKind.push(TerritoryWatchIssuerKindEnum.MRAE);
              }
              // Particular case because value is null (root item)
              if (item.text === 'Préfectures') {
                issuerKind.push(TerritoryWatchIssuerKindEnum.PREFECTURE);
              }
            }
          });
          break;
      }
    }
  }

  getProjectPublicationType(territoryWatchSettings: TerritoryWatchSettings): string[] {
    const projectPublicationTypes: any[] = [];
    // Navigation through the treeview items.
    for (const item of territoryWatchSettings.sourceStepSections.get('admin-docs')?.issuerGroups?.get('pref-and-mrae')?.treeviewItems || []) {
      item.children?.forEach((child) => {
            // we save project publication type only for a pref-and-mrae group that is in checked state
            if (territoryWatchSettings.sourceStepSections.get('admin-docs')?.issuerGroups?.get('pref-and-mrae')?.checked) {
              if (child.checked && child.value) {
                projectPublicationTypes.push(child.value);
              }
              child.children?.forEach((gchild) => {
                if (gchild.checked) {
                  projectPublicationTypes.push(gchild.value);
                }
              });
            }
          }
      );
    }
    return projectPublicationTypes;
  }

  /**
   * Method to create publication type from issuer group *search page*
   */
  getPublicationType(adminDocSourceSection: Map<string, AdminDocSourceIssuerGroupData>): string[] {
    const projectPublicationTypes: any[] = [];
    // Navigation through the treeview items.
    for (const item of adminDocSourceSection.get('pref-and-mrae')?.treeviewItems || []) {
      item.children?.forEach((child) => {
          if (child.checked && child.value) {
            projectPublicationTypes.push(child.value);
          }
          child.children?.forEach((gchild) => {
            if (gchild.checked) {
              projectPublicationTypes.push(gchild.value);
            }
          });
        }
      );
    }
    return projectPublicationTypes;
  }


  //region _object creator_
  /**
   * Params for the treeview items in the search part ≠ from the treeview items in the source step from alert funnel.
   */
  get prefAndMraeTreeviewItems_adminDocSearch() {
    return prefAndMraeTreeviewItems_adminDocSearch;
    /** Ha mon bon Philippe, si tu pouvais m'expliquer pourquoi le code qui suit altère l'objet cible initial : prefAndMraeTreeviewItems
     * Je te serai infiniment reconnaissant... J'ai essayé avec Slice() et avec {...Object} pour générer des shallow copies mais rien à faire ! */
    // const treeviewItemsCopy = {...prefAndMraeTreeviewItems.slice()};
    // const newGrandChildren = treeviewItemsCopy[0].children.find((elm) => elm.text === 'Préfectures')
    //   .children.filter((child) =>
    //     child.value !== TerritoryWatchProjectPublicationTypeEnum.ENQUETE_PUBLIQUE
    //   );
    // treeviewItemsCopy[0].children.find((elm) => elm.text === 'Préfectures')
    //   .children = newGrandChildren;
    // return treeviewItemsCopy;
  }
  //endregion

}

import { TerritoryWatchIssuerKindEnum, TerritoryWatchProjectPublicationTypeEnum } from '../../models/territory-watch-settings';

export const prefAndMraeTreeviewItems =
  [
    {
      infos: {type: 'pref-and-mrae'},
      isRoot: true,
      checked: true,
      collapsed: false,
      disabled: false,
      text: 'Toutes les administrations',
      value: '',
      children: [
        {
          infos: {type: 'pref-and-mrae', subtype: 'first-elem'},
          isRoot: false,
          checked: true,
          collapsed: false,
          disabled: false,
          text: 'MRAe (avis)',
          value: TerritoryWatchProjectPublicationTypeEnum.AVIS_MRAE
        },
        {
          infos: {type: 'pref-and-mrae'},
          isRoot: false,
          checked: true,
          collapsed: false,
          disabled: false,
          text: 'Préfectures',
          value: null,
          children: [
            {
              infos: {type: 'pref-and-mrae'},
              isRoot: false,
              checked: true,
              collapsed: false,
              disabled: false,
              text: 'Avis d\'ouverture d\'enquête publique',
              value: TerritoryWatchProjectPublicationTypeEnum.AVIS_ENQUETE_PUBLIQUE
            },
            {
              infos: {type: 'pref-and-mrae'},
              isRoot: false,
              checked: true,
              collapsed: false,
              disabled: false,
              text: 'Dossiers d\'enquête publique',
              value: TerritoryWatchProjectPublicationTypeEnum.ENQUETE_PUBLIQUE
            },
            {
              infos: {type: 'pref-and-mrae'},
              isRoot: false,
              checked: true,
              collapsed: false,
              disabled: false,
              text: 'Arrêtés préfectoraux',
              value: TerritoryWatchProjectPublicationTypeEnum.ARRETE
            }
          ]
        }
      ]
    }
  ];

export const prefAndMraeTreeviewItems_adminDocSearch =
    [
      {
        infos: {type: 'pref-and-mrae'},
        isRoot: true,
        checked: true,
        collapsed: false,
        disabled: false,
        text: 'Toutes les administrations',
        value: '',
        children: [
          {
            infos: {type: 'pref-and-mrae', subtype: 'first-elem'},
            isRoot: false,
            checked: true,
            collapsed: false,
            disabled: false,
            text: 'MRAe (avis)',
            value: TerritoryWatchProjectPublicationTypeEnum.AVIS_MRAE
          },
          {
            infos: {type: 'pref-and-mrae'},
            isRoot: false,
            checked: true,
            collapsed: false,
            disabled: false,
            text: 'Préfectures',
            value: null,
            children: [
              {
                infos: {type: 'pref-and-mrae'},
                isRoot: false,
                checked: true,
                collapsed: false,
                disabled: false,
                text: 'Avis d\'ouverture d\'enquête publique',
                value: TerritoryWatchProjectPublicationTypeEnum.AVIS_ENQUETE_PUBLIQUE
              },
              {
                infos: {type: 'pref-and-mrae'},
                isRoot: false,
                checked: true,
                collapsed: false,
                disabled: false,
                text: 'Arrêtés préfectoraux',
                value: TerritoryWatchProjectPublicationTypeEnum.ARRETE
              }
            ]
          }
        ]
      }
    ];

export const regionAndDepartmentTreeviewItems =
  [
    {
      infos: {type: 'region-and-department'},
      isRoot: true,
      checked: true,
      collapsed: false,
      disabled: false,
      text: 'Tous les conseils',
      value: '',
      children: [
        {
          infos: {type: 'region-and-department', subtype: 'first-elem'},
          isRoot: false,
          checked: true,
          collapsed: false,
          disabled: false,
          text: 'Conseils régionaux',
          value: TerritoryWatchIssuerKindEnum.FRREGI,
        },
        {
          infos: {type: 'region-and-department'},
          isRoot: false,
          checked: true,
          collapsed: false,
          disabled: false,
          text: 'Conseils départementaux',
          value: TerritoryWatchIssuerKindEnum.FRDEPA
        }
      ]
    }
  ];

export const regionAndDepartmentTreeviewItems_adminDocSearch =
  [
    {
      infos: {type: 'region-and-department'},
      isRoot: true,
      checked: true,
      collapsed: false,
      disabled: false,
      text: 'Tous les conseils',
      value: '',
      children: [
        {
          infos: {type: 'region-and-department', subtype: 'first-elem'},
          isRoot: false,
          checked: true,
          collapsed: false,
          disabled: false,
          text: 'Conseil régional',
          value: TerritoryWatchIssuerKindEnum.FRREGI,
        },
        {
          infos: {type: 'region-and-department'},
          isRoot: false,
          checked: true,
          collapsed: false,
          disabled: false,
          text: 'Conseil départemental',
          value: TerritoryWatchIssuerKindEnum.FRDEPA
        }
      ]
    }
  ];

export const commAndEpciTreeviewItems =
  [
    {
      infos: {type: 'comm-and-epci'},
      isRoot: true,
      checked: true,
      collapsed: false,
      disabled: false,
      text: 'Toutes les collectivités',
      value: '',
      children: [
        {
          infos: {type: 'comm-and-epci'},
          isRoot: false,
          checked: true,
          collapsed: false,
          disabled: false,
          text: 'Communes',
          value: TerritoryWatchIssuerKindEnum.FRCOMM
        },
        {
          infos: {type: 'comm-and-epci'},
          isRoot: false,
          checked: true,
          collapsed: false,
          disabled: false,
          text: 'EPCI',
          value: TerritoryWatchIssuerKindEnum.FREPCI
        }]
    }
  ];

export const EptTreeviewItem = {
  infos: { type: 'comm-and-epci' },
  isRoot: false,
  checked: true,
  collapsed: false,
  disabled: false,
  text: 'Établissements publics territoriaux',
  value: TerritoryWatchIssuerKindEnum.EPTP
};

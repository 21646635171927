export enum IssuerKindEnum {
  press = 'press',
  comm_and_epci = 'comm-and-epci',
  region_and_department = 'region-and-department',
  pref_and_mrae = 'pref-and-mrae',
  syndicates = 'syndicates',
}

export enum IssuerSectionEnum {
  article = 'article',
  admin_doc = 'admin-doc',
  region_and_department = 'region-and-department',
  pref_and_mrae = 'pref-and-mrae',
}

import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TerritoryWatchProjectPublicationTypeEnum } from '../../models/territory-watch-settings';
import { ContextService } from '../../shared/services/context.service';
import { TerritoryForAdminDoc } from '../../models/territory/territory-utils';

@Component({
  selector: 'app-pref-and-mrae-doc-card',
  templateUrl: './pref-and-mrae-doc-card.component.html',
  styleUrls: ['./pref-and-mrae-doc-card.component.scss']
})
export class PrefAndMraeDocCardComponent implements OnChanges, AfterViewInit {

  @Input({ required: true }) docId!: string;
  @Input() allowOnClick = true;
  @Input() showOccurrences = true;
  @Input() title?: string;
  @Input() publicationDate?: Date;
  @Input() nbOccurrences: number = 0;
  @Input() text: string = "";
  @Input() url?: string;
  @Input() website?: string;
  @Input() isPreview = false;
  @Input() folderId?: number;
  @Input() displayedPublicationType?: string = TerritoryWatchProjectPublicationTypeEnum.NON_DETECTED;
  @Input() publicationType?: TerritoryWatchProjectPublicationTypeEnum;
  @Input() projectUid?: string;
  @Input() inquirers?: string[];
  @Input() nbNewDocuments?: number;
  @Input() territories?: Array<TerritoryForAdminDoc>;

  displayReadMore = true;
  displayedUrl = '';

  TerritoryWatchProjectPublicationTypeEnum = TerritoryWatchProjectPublicationTypeEnum;

  constructor(private contextService: ContextService) { }


  ngOnChanges(changes: SimpleChanges) {
    if (this.text as any instanceof Array) {
      // transforming string[] into a string.
      this.text = this.text[0];
    }
  }

  ngAfterViewInit() {
    const mightOverflowElement = document?.getElementById('territory-list-' + this.docId);
    // If the displayed list of territories takes more space than allowed.
    this.displayReadMore = !!mightOverflowElement && (mightOverflowElement.offsetWidth < mightOverflowElement.scrollWidth);
    if (this.url) {
      const longUrl = new URL(this.url);
      this.displayedUrl = longUrl.origin;
      // Case of arrete or avis_mrae - take the website as url to display
      if ([TerritoryWatchProjectPublicationTypeEnum.ARRETE, TerritoryWatchProjectPublicationTypeEnum.AVIS_MRAE]
        .includes(this.publicationType as TerritoryWatchProjectPublicationTypeEnum)) {
        this.displayedUrl = this.website ?? '';
      }
    } if (this.text && this.text?.length < 5) { // case excerpt:[''] i don't know how to catch this in another way...
      this.text = "";
    }
  }
  onReadMoreClick(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }


  onClickCard() {
    this.contextService.setSnippetWithExpiry(this.docId, this.text);
  }
}

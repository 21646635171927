import { Component, ElementRef, Input, ViewChild, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { TerritorySearch } from '../../core/territory-search/territory-search';
import { TerritoryHelper } from '../../shared/helpers/territory.helper';
import { TreeItem, TreeviewConfig, TreeviewItem } from '../../lib/ngx-treeview';
import { TerritoryWatchSettingService } from '../../shared/services/territory-watch-setting.service';
import { StepModel } from '../../core/step/step.model';
import { first } from 'rxjs/operators';
import { NotiferService } from 'src/app/shared/services/notifer.service';
import { StepsService } from '../../shared/services/steps.service';
import { TerritoryWatchTerritory } from '../../models/territory-watch-settings';
import { ApiService } from '../../shared/services/api/api.service';
import { TerritoryKind } from '../../core/territory-kind/territory-kind.enum';
import { PerimeterTerritory, TerritoryForAccountInfo } from '../../models/territory/territory-utils';
import { SearchHelper } from '../../shared/helpers/search.helper';
import { TerritoryManager } from '../../models/territory/territory-manager';
import { EventTypeName } from '../../models/user-tracker';
import { UserTrackerService } from '../../shared/services/tracking/user-tracker.service';
import { lastValueFrom } from 'rxjs';
import { Territory } from '../../models/territory/territory';


@Component({
  selector: 'app-perimeter-step-template',
  templateUrl: './perimeter-step-template.component.html',
  styleUrls: ['./perimeter-step-template.component.scss']
})
export class PerimeterStepTemplateComponent extends TerritorySearch implements OnInit, OnDestroy {
  @ViewChild('treeview') treeview?: ElementRef;
  @Input() step?: StepModel;
  @Input({required: true}) isComponentHidden!: boolean;
  @Input() maxDepartmentNumber: number = 0;
  @Output() totalDepartments = new EventEmitter<{ total: number, context: string }>();
  @Output() selectedDepartments = new EventEmitter<{ selected: number, context: string }>();
  territoriesSetting?: Array<TerritoryWatchTerritory>;
  EventTypeName = EventTypeName;
  PerimeterTrackingName = {
    departmentList: 'department-list',
    departmentTree: 'department-tree'
  };
  territories: Array<PerimeterTerritory> = [];
  territoryRequests: Array<Promise<any>> = [];
  openTab?: string;
  isAllTerritories: boolean;
  config = TreeviewConfig.create({
    hasFilter: true,
    decoupleChildFromParent: false,
    maxHeight: 270
  });
  filterText = '';
  nbMatchSearch = 0;

  clickOrPressDebounceDueTime = 0;

  constructor(
    private stepsService: StepsService,
    private notif: NotiferService,
    protected override apiService: ApiService,
    private alertSettingService: TerritoryWatchSettingService,
    private userTrackerService: UserTrackerService,
  ) {
      super(apiService);
      this.isAllTerritories = true;
      this.stepsService.updatePerimetersRetrievingState(false);
  }

  ngOnInit() {
    /** Récupération des périmètres */
    this.alertSettingService.areSettingsRetrieved$
      .pipe(first())
      .subscribe(() => {
        this.territoriesSetting = this.alertSettingService.territoryWatchSettings.territories;

        if (!this.territoriesSetting.length) { return; }

        this.territoriesSetting.filter(territory => !territory.territory.includes(TerritoryKind.REGION))
          .forEach(territory => {
          this.addNewDepartment(territory.territory, territory.values);
        });

        Promise.all(this.territoryRequests).then(() => {
          this.stepsService.updatePerimetersRetrievingState(true);
          this.stepsService.updateStepCompletionState(0, true);
        });
      });

    this.apiService.exStatistics.AccountInfoReplaySubject
      .pipe(first())
      .subscribe((response) => {
        this.filteredList = response.territories.filter(elem => elem.kind === TerritoryKind.DEPARTMENT);
        this.totalDepartments.emit({total: this.filteredList.length, context: 'departments'});
      });
  }

  ngOnDestroy() {
    // Abort all pending requests
    this.territoryRequests = [];
  }

  onClickDepartment(territory: TerritoryForAccountInfo, disabled?: boolean) {
    if (!disabled) {
      const territoryUid = territory.uid;
      this.addNewDepartment(territoryUid);
      this.search('');
      this.territoryName = '';
      this.activeDepartmentIndex = 0; // select the first item in the list when a new input/letter is typed
      this.hideIfOpen();
      this.userTrackerService.trackEvent(
        EventTypeName.FILTER_CHANGE,
        this.PerimeterTrackingName.departmentList,
        'alert',
        'add',
        territoryUid
      );
    }
  }

  addNewDepartment(territoryUid: string, checkedUid: string[] = []) {
    const request = lastValueFrom(this.apiService.territory.retrieveTerritories(territoryUid, true, true))
      .then((territoryManager: TerritoryManager) => {
        // Abort adding department if the request has been removed from the requests list
        if (!this.territoryRequests.some((x) => x === request)) { return; }

        this.alertSettingService.addTerritoryManager(territoryUid, territoryManager);
        const data = territoryManager.territory;
        this.resetTextSearch();
        const uid = data.uid;
        if (this.territories.some((x) => x['uid'] === uid)) {
          return;
        }
        const region = this.getRegion(data.region);
        const treeviews = this.getItemTreeview(data, uid, checkedUid);

        const checkedItems = treeviews.reduce((acc, val) => {
          return acc.concat(val.getSelection().checkedItems.map(k => k.value));
        }, new Array<string>());
        const uncheckedItems = treeviews.reduce((acc, val) => {
          return acc.concat(val.getSelection().uncheckedItems.map(k => k.value));
        }, new Array<string>());
        const nbItems = checkedItems.length + uncheckedItems.length;
        const territory = {
          name: `${data.code} - ${data.name}`,
          uid,
          region: region,
          treeviews,
          collapsed: false,
          checkedItems,
          nbItems,
          allChecked: uncheckedItems.length === 0,
          new: !checkedUid.length,
        };
        this.resetNewItemStats();
        this.territories.unshift(territory);
        setTimeout(() => {
          this.resetNewItemStats();
        }, 5000);
        this.isAllTerritories = true;
        this.selectedDepartments.emit({selected: this.territories.length, context: 'departments'});
      }).finally(() => {
        // Remove completed request from the requests list
        this.territoryRequests = this.territoryRequests.filter(p => p !== request);
        this.sendTerritories();
      });
    // Add request to the requests list
    this.territoryRequests.push(request);
    this.sendTerritories();
  }

  getRegion(region: Territory | undefined): PerimeterTerritory | undefined {
    if (region) {
      return {
        name: region.name,
        uid: region.uid,
        treeviews: [],
        collapsed: true,
        checkedItems: [region.uid],
        nbItems: 1,
        allChecked: true,
        new: false,
      };
    } else return;
  }

  getItemTreeview(territories: Territory, uid: string, checkedUid: string[]): TreeviewItem[] {
    if (checkedUid.length) {
      return TerritoryHelper.transferToTreeview([territories], true, true, checkedUid);
    } else {
      if (!this.isAllTerritories) {
        this.openTab = uid;
        return TerritoryHelper.transferToTreeview([territories], true, true, false);
      } else {
        return TerritoryHelper.transferToTreeview([territories], true, true);
      }
    }
  }

  resetNewItemStats(): void {
    if (this.territories.length) {
      this.territories.forEach(x => x['new'] = false);
    }
  }

  onRemoveDepartment(territoryIndex: number): void {
    const [deleted] = this.territories.splice(territoryIndex, 1);
    this.alertSettingService.removeTerritoryManager(deleted['uid']);
    this.selectedDepartments.emit({selected: this.territories.length, context: 'departments'});
    this.sendTerritories();
    this.userTrackerService.trackEvent(
      EventTypeName.FILTER_CHANGE,
      this.PerimeterTrackingName.departmentList,
      'alert',
      'remove',
      deleted['uid']
    );
  }

  openDepartmentTree(territoryUid: string) {
    this.openTab = territoryUid;
    this.resetTextSearch();
  }

  closeDepartmentTree() {
    this.openTab = undefined;
    this.resetTextSearch();
  }

  setAllTerritories(value: boolean) {
    this.isAllTerritories = value;
  }

  test(value: any) {
    this.notif.notifer(value);
  }

  onSelectedChange(value: Array<any>): void {
    const territoryIndex = this.territories.findIndex(item => item['uid'] === this.openTab);
    const territory = this.territories[territoryIndex];
    this.territories[territoryIndex]['checkedItems'] = value;
    this.territories[territoryIndex]['allChecked'] = value.length === territory['nbItems'];
    this.sendTerritories();
  }

  onSelectAllChange(territoryIndex: number) {
    this.notif.notifer(this.territories[territoryIndex]['treeviews'][0]);
    const territories = this.territories[territoryIndex];
    territories['treeviews'].forEach((child: any) => child.setCheckedRecursive(territories['allChecked']));
    territories['checkedItems'] = territories['treeviews'].reduce((acc, val) => {
      return acc.concat(val.getSelection().checkedItems.map(k => k.value));
    }, new Array<string>());
    this.sendTerritories();

  }

  collapse(territoryIndex: number) {
    this.territories[territoryIndex]['collapsed'] = !this.territories[territoryIndex]['collapsed'];
    this.territories[territoryIndex]['treeviews'].forEach(
      child => child.setCollapsedRecursive(this.territories[territoryIndex]['collapsed']));
  }

  sendTerritories() {
    const map = new Map<string, {total_nb_locations: number, values: Array<string>}>
    this.territories.forEach(territory => {
      map.set(territory.uid, {total_nb_locations: territory.nbItems, values: territory.checkedItems});
      map.set(territory.region!.uid, {total_nb_locations: 1, values: [territory.region!.uid]});
    })
    const data = Array.from(map, ([key, value]) => ({ territory: key, ...value }));

    this.alertSettingService.setTerritories(data);
    this.ifDepartmentExists(data);
  }

  resetTextSearch(): void {
    this.filterText = '';
    this.nbMatchSearch = 0;
  }

  onFilterChange(_: any): void {
    setTimeout(() => {
      this.nbMatchSearch = this.treeview?.nativeElement?.querySelectorAll('b').length;
    }, 500);
  }

  onTargetItem(territoryIndex: number): void {
    this.territories[territoryIndex]['treeviews'].forEach(child => child.setCheckedRecursive(false));
  }

  getTargetLabel(item: TreeItem): string {
    const kind = item.infos['kind'];
    if (['FRCOMM', 'FREPCI'].includes(kind)) {
      return `wizard.${kind}`;
    } else {
      return '';
    }
  }

  override checkItemSelected(territory: TerritoryForAccountInfo): boolean {
    return this.territories.map(x => x['uid'])?.includes(territory.kind + territory.code);
  }

  ifDepartmentExists(data: any) {
    /*cette function permet de renvoyer un signal au component parent pour lui informer si
    il n'a pas aucune departement séléctionner*/
    if (this.checkIfAtLeastOneZoneChecked(data)) {
      if (this.territories.length === 0) {
        this.stepsService.updateStepCompletionState(0, false);
      } else {
        // Any additive change invalidates the overview step.
        this.stepsService.updateStepCompletionState(4, false);
        // Allow next step only if there is no pending requests
        if (this.territoryRequests.length === 0) {
          this.stepsService.updateStepCompletionState(0, true);
        } else {
          this.stepsService.updateStepCompletionState(0, false);
        }
      }
    } else {
      this.stepsService.updateStepCompletionState(0, false);
    }
  }

  checkIfAtLeastOneZoneChecked(data: any): boolean {
    /* cette fonction permet de verifier si dans tout les department au moin une commune a ete selectionner */
    for (let i = 0; i < data.length; i++) {
      if (data[i]['values'].length === 0) {
        return false;
      }
    }
    return true;
  }

  clickOutsideDropdownMenu() {
    if (this.show) {
      this.show = false;
    }
  }

  onSelectOrEnterPressed(territory: any) {
    if (this.territories.length + this.territoryRequests.length < (this.maxDepartmentNumber || 0) && !this.isComponentHidden) {
      if (!this.show) {
        this.activeDepartmentIndex = this.getMinActiveIndex();
        this.show = true;
      } else {
        this.onClickDepartment(territory, this.checkItemSelected(territory));
        // waiting as much time as for the clickOrPressDebounce$ of the directive.
        // Avoiding ui bugs when using enter too rapidly.
        setTimeout(() => this.activeDepartmentIndex = this.getMinActiveIndex(), this.clickOrPressDebounceDueTime);
      }
    }
  }

  retrieveDueTime(dueTime: number) {
    this.clickOrPressDebounceDueTime = dueTime;
  }

  getMinActiveIndex(): number {
    let indexToReturn = 0;

    const selectedUids = this.territories.map((terr) => {
      return terr['uid'];
    });

    for (let i = 0; i < this.filteredList.length; i++) {
      if (selectedUids?.includes(this.filteredList[i].kind + this.filteredList[i].code)) {
        indexToReturn++;
      } else {
        return indexToReturn;
      }
    }
    return indexToReturn;
  }

  override updateActiveDepartmentIndex(isArrowDownPressed: boolean) {
    const index = this.activeDepartmentIndex;

    this.activeDepartmentIndex = isArrowDownPressed ? // cases DOWN or UP on vertical dropdown list.
      (index + 1 >= this.filteredList.length) ? 0 : (index + 1) : // DOWN > if at the end, go first elem, else increment.
      (index - 1 === -1) ? (this.filteredList.length - 1) : (index - 1); // UP > if at the begining, go last elem, else decrement.

    const selectedUids = this.territories.map((terr) => {
      return terr['uid'];
    });

    // Case department already selected.
    if (selectedUids?.includes(this.filteredList[this.activeDepartmentIndex].kind + this.filteredList[this.activeDepartmentIndex].code)) {
      // Going one step further.
      this.updateActiveDepartmentIndex(isArrowDownPressed);
    }
  }

  // Overriding extended class method to update local activeIndex.
  override search(value: string) {
    this.show = true;
    this.selectedUid = '';
    this.filteredList = this.territoriesList.filter((v: TerritoryForAccountInfo) => SearchHelper.searchRegExp(v.code + v.name, value));
    this.activeDepartmentIndex = this.getMinActiveIndex();
  }

  hideItem(item: TreeviewItem): boolean {
    if (item.children) {
      if (item.isRoot) {
        return false;
      } else {
        const childInSearch = item.children.some(x => !!SearchHelper.searchRegExp(x.text, this.filterText));
        return !childInSearch && !SearchHelper.searchRegExp(item.text, this.filterText);
      }
    } else {
      return !SearchHelper.searchRegExp(item.text, this.filterText);
    }
  }

}

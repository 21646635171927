import { Injectable } from '@angular/core';
import {
  DbIssuerGroup,
  DbSourceStepSection,
  TerritoryWatchIssuerKindEnum,
  TerritoryWatchProjectPublicationTypeEnum,
  TerritoryWatchSettings
} from '../../models/territory-watch-settings';
import { flattenTreeview, TreeviewItem } from '../../lib/ngx-treeview';
import { commAndEpciTreeviewItems, prefAndMraeTreeviewItems, regionAndDepartmentTreeviewItems } from './source-treeview-items-data';
import { Territory } from '../../models/territory/territory';
import { TerritoryManager } from '../../models/territory/territory-manager';
import { makeSyndicatesTreeview } from '../../shared/helpers/territory.helper';
import { TerritoryWatchSettingService } from '../../shared/services/territory-watch-setting.service';
import { TranslateService } from '@ngx-translate/core';
import {
  DEFAULT_PROJECT_DOCS_ISSUER_GROUP_DATA,
  DEFAULT_ADMIN_DOCS_SOURCE_STEP_SECTION_DATA,
  DEFAULT_ARTICLE_SOURCE_STEP_SECTION_DATA,
  DEFAULT_COMM_AND_EPCI_ISSUER_GROUP_DATA,
  DEFAULT_PRESS_ISSUER_GROUP_DATA,
  DEFAULT_SYNDICATES_ISSUER_GROUP_DATA,
  DEFAULT_REGION_DEPARTMENT_ISSUER_GROUP_DATA, DefaultIssuerGroupData
} from './source-step-service-data';

@Injectable({
  providedIn: 'root'
})
export class SourceStepService {

  syndicates?: TreeviewItem[];
  syndicatesReg?: { [scope: string]: Territory[] };
  citySyndicates?: { [uid: string]: string[] };
  validSyndicates?: string[];
  syndicatesStore?: string[];
  selectedSyndicates?: string[];
  isFromAlert = false;

  constructor(private alertSettingService: TerritoryWatchSettingService,
              private translate: TranslateService) {
  }

  //region —— syndicates methods ——
  setUpSyndicatesAttributes(registry: { [p: string]: TerritoryManager }) {
    let syndicates : any = {};
    const citySyndicates: any = {};
    Object.values(registry).forEach((manager) => {
      Object.entries(manager.syndicates).forEach(([scope, list]) => {
        syndicates[scope] = [
          ...syndicates[scope] ?? [],
          ...list.filter((syndicate) => !syndicates[scope]?.some((item: Territory) => item.uid === syndicate.uid))];
      });
      Object.entries(manager.citySyndicates).forEach(([uid, list]) => {
        citySyndicates[uid] = [...citySyndicates[uid] ?? [], ...list];
      });
    });
    syndicates = TerritoryManager.sortSyndicates(syndicates);
    this.syndicatesReg = syndicates;
    this.citySyndicates = citySyndicates;
    const currentSelection = this.syndicates?.map((treeview) => treeview.getSelection());
    this.syndicates = makeSyndicatesTreeview(syndicates, this.translate.instant('filter.all-syndicates'));
    this.syndicatesStore = this.syndicates.reduce(
      (res, treeview) => [...res, ...treeview.getSelection().checkedItems.map((item) => item.value)],
      new Array<string>()
    );
    this.syndicates.forEach((item, i) => item.setCheckedRecursive(true, undefined, currentSelection?.[i]));
    this.onSelectedSyndicateChange(this.syndicates.reduce(
      (res, treeview) => [...res, ...treeview.getSelection().checkedItems.map((item) => item.value)],
      new Array<string>()
    ));
  }

  updateValidSyndicates(perimeter: { territory: any, values: any }[]) {
    this.validSyndicates = perimeter.reduce(
      (res, node) => [
        ...res,
        ...this.alertSettingService.territoryManagers.value[node.territory]
          ?.getCorrespondingSyndicates(node.values) || []
      ], new Array<string>()
    );
  }

  onSelectedSyndicateChange(value: Array<any>): void {
    this.selectedSyndicates = value;
    this.alertSettingService.setSyndicates(value);
  }

  setCheckedRecursiveSyndicates(territoryWatchSettings: TerritoryWatchSettings) {
    this.syndicates?.forEach((item) => item.setCheckedRecursive(
      true,
      (syndicate) => territoryWatchSettings.syndicates?.includes(syndicate.value)
    ));
    if (territoryWatchSettings.syndicates.length) {
      this.onSelectedSyndicateChange(territoryWatchSettings.syndicates);
    }
  }

  updateSyndicatesOnSourceStepSections(sourceStepSections: Map<string, DbSourceStepSection>) {
    const syndicatesGroup = sourceStepSections.get('admin-docs')?.issuerGroups?.get('syndicates')
    if (syndicatesGroup && this.syndicates && this.syndicatesStore) {
      syndicatesGroup.treeviewItems = this.syndicates;
      syndicatesGroup.itemsStore = this.syndicatesStore;
    }
  }

  //endregion

  //region —— source step section with DATA ——

  /**
   * Building source step sections, w/ specific objects and methods.
   */
  setUpSourceStepSectionFromScratch(territoryWatchSettings: TerritoryWatchSettings) {
    // set issuerGroups map for article section
    const articleIssuerGroups = new Map<string, DbIssuerGroup>();
    articleIssuerGroups.set('press', new DefaultIssuerGroupData(DEFAULT_PRESS_ISSUER_GROUP_DATA, true, [], [], []));
    // Ajout press section
    const articleSourceStepSection = DEFAULT_ARTICLE_SOURCE_STEP_SECTION_DATA;
    articleSourceStepSection.issuerGroups = articleIssuerGroups;
    territoryWatchSettings.sourceStepSections.set('articles', articleSourceStepSection);

    // Set issuerGroups map for admin_docs section
    const adminDocsIssuerGroups = new Map<string, DbIssuerGroup>();
    adminDocsIssuerGroups.set('region-and-department',
      new DefaultIssuerGroupData(
        DEFAULT_REGION_DEPARTMENT_ISSUER_GROUP_DATA,
        true,
        regionAndDepartmentTreeviewItems.map((item) => new TreeviewItem(item)),
        flattenTreeview(regionAndDepartmentTreeviewItems.map((item) => new TreeviewItem(item))),
        [])
    );
    adminDocsIssuerGroups.set('pref-and-mrae',
        new DefaultIssuerGroupData(
            DEFAULT_PROJECT_DOCS_ISSUER_GROUP_DATA,
            true,
            prefAndMraeTreeviewItems.map((item) => new TreeviewItem(item)),
            flattenTreeview(prefAndMraeTreeviewItems.map((item) => new TreeviewItem(item))),
            [])
    );
    adminDocsIssuerGroups.set('syndicates', new DefaultIssuerGroupData(DEFAULT_SYNDICATES_ISSUER_GROUP_DATA, true, [], [], []));
    adminDocsIssuerGroups.set('comm-and-epci',
      new DefaultIssuerGroupData(DEFAULT_COMM_AND_EPCI_ISSUER_GROUP_DATA,
        true,
        commAndEpciTreeviewItems.map((item) => new TreeviewItem(item)),
        flattenTreeview(commAndEpciTreeviewItems.map((item) => new TreeviewItem(item))),
        [])
    );

    // Add admin doc section
    const adminDocsSourceStepSection = DEFAULT_ADMIN_DOCS_SOURCE_STEP_SECTION_DATA;
    adminDocsSourceStepSection.issuerGroups = adminDocsIssuerGroups;
    territoryWatchSettings.sourceStepSections?.set('admin-docs', adminDocsSourceStepSection);
  }

  /**
   * Building source step sections, w/ specific objects and methods.
   * Method : building object from scratch, then updating attributes with values of territoryWatchSettings.
   */
  setUpSourceStepSectionOfTerritoryWatchSettings(territoryWatchSettings: TerritoryWatchSettings) {
    // 1. Building necessary objects for setup
    // Build object from scratch
    this.setUpSourceStepSectionFromScratch(territoryWatchSettings);
    // Building necessary objects for setup
    territoryWatchSettings.sourceStepSections.get('admin-docs')?.issuerGroups?.get('pref-and-mrae')?.treeviewItems.forEach((item) =>
      item.setCheckedRecursive(true, (elem) =>
        territoryWatchSettings.issuerKind?.includes(elem.value as TerritoryWatchIssuerKindEnum) ||
        territoryWatchSettings.projectPublicationType?.includes(elem.value as TerritoryWatchProjectPublicationTypeEnum)
      )
    );
    territoryWatchSettings.sourceStepSections.get('admin-docs')?.issuerGroups?.get('comm-and-epci')?.treeviewItems.forEach((item) =>
      item.setCheckedRecursive(true, (elem) =>
        territoryWatchSettings.issuerKind?.includes(elem.value as TerritoryWatchIssuerKindEnum)
      )
    );
    territoryWatchSettings.sourceStepSections.get('admin-docs')?.issuerGroups?.get('region-and-department')?.treeviewItems.forEach((item) =>
      item.setCheckedRecursive(true, (elem) =>
        territoryWatchSettings.issuerKind?.includes(elem.value as TerritoryWatchIssuerKindEnum)
      )
    );

    // 2. Setting attributes to territory watch setting
    // PRESS — set checked status
    const pressGroup = territoryWatchSettings.sourceStepSections.get('articles')?.issuerGroups?.get('press')
    if (pressGroup) pressGroup.checked = territoryWatchSettings.issuerKind?.includes(TerritoryWatchIssuerKindEnum.PRESS);
    // PREF AND MRAE — set treeviewItems and checked status
    const prefMraeGroup = territoryWatchSettings.sourceStepSections.get('admin-docs')?.issuerGroups?.get('pref-and-mrae')
    if (prefMraeGroup) prefMraeGroup.checked = territoryWatchSettings.issuerKind?.includes(TerritoryWatchIssuerKindEnum.PREFECTURE) ||
      territoryWatchSettings.issuerKind?.includes(TerritoryWatchIssuerKindEnum.MRAE);
    // SYNDICATES — set selectedItems
    const syndGroup = territoryWatchSettings.sourceStepSections.get('admin-docs')?.issuerGroups?.get('syndicates')
    if (syndGroup) {
      syndGroup.checked = territoryWatchSettings.issuerKind?.includes(TerritoryWatchIssuerKindEnum.SYNDICAT);
      syndGroup.selectedItems = territoryWatchSettings.syndicates;
    }
    // COMMUNES AND EPCI — set treeviewItems, itemStore and checked status
    const commEpciGroup = territoryWatchSettings.sourceStepSections.get('admin-docs')?.issuerGroups?.get('comm-and-epci')
    if (commEpciGroup) commEpciGroup.checked = territoryWatchSettings.issuerKind?.includes(TerritoryWatchIssuerKindEnum.FRCOMM) ||
      territoryWatchSettings.issuerKind?.includes(TerritoryWatchIssuerKindEnum.FREPCI) ||
      territoryWatchSettings.issuerKind?.includes(TerritoryWatchIssuerKindEnum.EPTP);
    // REGION AND DEPARTMENT — set treeviewItems, itemStore and checked status
    const regionDepartmentGroup = territoryWatchSettings.sourceStepSections.get('admin-docs')?.issuerGroups?.get('region-and-department')
    if (regionDepartmentGroup) {
      regionDepartmentGroup.checked = territoryWatchSettings.issuerKind?.includes(TerritoryWatchIssuerKindEnum.FRDEPA) ||
        territoryWatchSettings.issuerKind?.includes(TerritoryWatchIssuerKindEnum.FRREGI);
    }
  }

  //endregion

}


import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ContextService } from '../../shared/services/context.service';
import { DisplayedPublicationType } from '../../models/displayed-publication-type';
import { MatchStem } from '../../models/match-stem';
import { MatchStemsService } from '../../shared/services/match-stems.service';
import { DbDisplayHelperForDocumentEntity } from '../admin-docs-page/admin-docs-page.component';
import { TerritoryForAdminDoc } from '../../models/territory/territory-utils';
import { TerritoryKind } from '../../core/territory-kind/territory-kind.enum';

@Component({
  selector: 'app-admin-doc-card',
  templateUrl: './admin-doc-card.component.html',
  styleUrls: ['./admin-doc-card.component.scss']
})
export class AdminDocCardComponent implements OnInit, AfterViewInit {
  @Input() docId!: string;
  @Input() allowOnClick = true;
  @Input() redirectsToUrl = false;
  @Input() showOccurrences = true;
  @Input() title!: string;
  @Input() publicationDate!: Date | null;
  @Input() nbOccurrences!: number;
  @Input() website?: string;
  @Input() text!: string;
  @Input() url?: string;
  @Input() isPreview = false;
  @Input() folderId!: number;
  @Input() bookmarkPaths: any;
  @Input() displayedPublicationType?: string;
  @Input() matchStems!: MatchStem[];
  @Input() territories!: TerritoryForAdminDoc[];
  @Input() displayHelper!: DbDisplayHelperForDocumentEntity;
  @Input() projectUid!: string;
  displayReadMore = true;
  displayExtLinkIcon = false;

  protected readonly TerritoryKind = TerritoryKind;

  DisplayedPublicationType = DisplayedPublicationType;

  constructor(private contextService: ContextService,
              private matchStemsService: MatchStemsService) { }

  ngOnInit(): void {
    // Manage case error, no publicationType provided.
    if (!this.displayedPublicationType) {
      this.displayedPublicationType = DisplayedPublicationType.NON_DETECTED;
    }
  }

  ngAfterViewInit() {
    const mightOverflowElement = document.getElementById('territory-list-' + this.docId);
    // If the displayed list of territories takes more space than allowed.
    this.displayReadMore = !!mightOverflowElement && (mightOverflowElement.offsetWidth < mightOverflowElement.scrollWidth);
  }

  onReadMoreClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onClickCard() {
    this.contextService.setSnippetWithExpiry(this.docId, this.text);
    this.contextService.setProjectUidWithExpiry(this.docId, this.projectUid);
    this.matchStemsService.setMatchStemsWithExpiry(this.docId, this.matchStems);
  }

  getRouterLink() {
    // if allowOnClick false : we do not allow redirection or click on cards
    // if redirectToUrl true : we redirects to external url, so routerLink null
    // if allowOnClick and NO redirectToUrl : we go to the document page via its docId
    return this.allowOnClick ? (this.redirectsToUrl ? null : this.docId) : null;
  }

}

import { EventEmitter, Injectable } from '@angular/core';
import { TerritoryManager } from '../../models/territory/territory-manager';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  creationDbTerritoryWatchSettings,
  TerritoryWatchSettings,
  TerritoryWatchTerritoryDb
} from '../../models/territory-watch-settings';
import { TerritoryWatchOverviewParams } from '../../models/territory-watch/territory-watch-overview-params';
import { IssuerMethodsService } from './issuer-methods.service';

@Injectable({
  providedIn: 'root'
})
export class TerritoryWatchSettingService {
  name?: string;
  topicIds = new Array<string>();
  competitorMonitoring = false;
  territories = new Array<TerritoryWatchTerritoryDb>();
  syndicates = new Array<string>();
  territoryManagers = new BehaviorSubject<{[uid: string]: TerritoryManager}>({});
  onPerimeterChange = new EventEmitter();

  limit = 50;
  territoryWatchOffset = 0;
  offset = 0;
  territoryWatchSettings: TerritoryWatchSettings = new TerritoryWatchSettings(creationDbTerritoryWatchSettings);
  areSettingsRetrieved$ = new Subject<boolean>();
  constructor(
    private issuerMethodsService: IssuerMethodsService
  ) {
  }

  reset(): void {
    this.name = undefined;
    this.territoryWatchSettings = new TerritoryWatchSettings(creationDbTerritoryWatchSettings);
    this.topicIds = [];
    this.competitorMonitoring = false;
    this.territories = [];
    this.syndicates = [];
    this.territoryManagers = new BehaviorSubject<{[uid: string]: TerritoryManager}>({});
    this.limit = 50;
    this.territoryWatchOffset = 0;
    this.offset = 0;
  }

  setTerritoryWatchSettings(TerritoryWatchSettings: TerritoryWatchSettings) {
    this.territoryWatchSettings = TerritoryWatchSettings;
    this.areSettingsRetrieved$.next(true);
  }

  setTopics(topicIds: string[]): void {
    this.topicIds = topicIds;
  }

  setCompetitorMonitoring(competitorMonitoring: boolean): void {
    this.competitorMonitoring = competitorMonitoring;
  }

  setFrequent(frequent: string): void {
    this.territoryWatchSettings.dayOfWeek = frequent;
  }

  setName(name: string): void {
    this.name = name;
  }

  getName() {
    return this.name;
  }

  setTerritories(territories: Array<TerritoryWatchTerritoryDb>): void {
    this.territories = territories;
    this.onPerimeterChange.emit(territories);
  }

  setSyndicates(syndicates: Array<string>): void {
    this.syndicates = syndicates;
  }

  addTerritoryManager(key: string, manager: TerritoryManager) {
    this.territoryManagers.next({...this.territoryManagers.value, [key]: manager});
  }

  removeTerritoryManager(key: string) {
    const territoryManagers = {...this.territoryManagers.value};
    delete territoryManagers[key];
    this.territoryManagers.next(territoryManagers);
  }

  getSetting(): any {
    return {
      name: this.name,
      day_of_week: this.territoryWatchSettings.dayOfWeek,
      issuer_kind: this.issuerMethodsService.getIssuerKind(this.territoryWatchSettings),
      project_publication_type: this.issuerMethodsService.getProjectPublicationType(this.territoryWatchSettings),
      topics_ids: this.topicIds,
      territories: this.territories,
      syndicates: this.syndicates,
    };
  }

  getOverviewParams(): TerritoryWatchOverviewParams {
    return new TerritoryWatchOverviewParams(
      this.territoryWatchSettings.dayOfWeek,
      this.territoryWatchSettings.issuerKind,
      this.territoryWatchSettings.projectPublicationType,
      this.topicIds,
      this.territoryWatchSettings.territories,
      this.territoryWatchSettings.syndicates,
      this.limit,
      this.territoryWatchOffset,
      this.offset
    );
  }
}

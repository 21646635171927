<div [hidden]="docId" class="contain" #adminDoc id="{{adminDocId}}">
  <app-stats-page *ngIf="totalCount === null" pageName="admin-doc" [sourceCount]="nbTotalWebsite" [totalCount]="nbTotalAdminDoc"></app-stats-page>
  <app-result-message *ngIf="totalCount === 0"></app-result-message>
  <app-loader></app-loader>
  <div [hidden]="!totalCount" class="list-content">
    <ng-container *ngFor="let entity of entities">
      <app-admin-doc-card
        data-cy="admin-doc-card"
        [bookmarkPaths]="entity['bookmark_paths']"
        [docId]="entity.id"
        [title]="entity.title"
        [publicationDate]="entity.publication_date"
        [website]="entity.website"
        [nbOccurrences]="entity['occurrence_count']"
        [text]="entity['excerpt'][0]"
        [url]="entity['url']"
        [displayedPublicationType]="entity.displayed_publication_type"
        [matchStems]="entity.match_stems"
        [displayHelper]="entity['display_helper']"
        [territories]="entity.territories"
        [redirectsToUrl]="entity['redirects_to_url']"
        [projectUid]="entity.project_uid"
      >
      </app-admin-doc-card>
    </ng-container>
    <app-pagination *ngIf="nbPages > 1" [nbPages]="nbPages" [stats]="dataUpdated"></app-pagination>
  </div>
</div>
<section>
  <router-outlet></router-outlet>
</section>

import { Injectable } from '@angular/core';
import { TreeviewItem } from '../../lib/ngx-treeview';
import {
  commAndEpciTreeviewItems,
  regionAndDepartmentTreeviewItems_adminDocSearch
} from '../../components/source-step-template/source-treeview-items-data';
import {
  AdminDocSourceIssuerGroupData,
  DEFAULT_COMM_AND_EPCI_ISSUER_GROUP_DATA,
  DEFAULT_REGION_AND_DEPARTMENT_ISSUER_GROUP_DATA,
  DEFAULT_PREF_AND_MRAE_ISSUER_GROUP_DATA,
  DEFAULT_SYNDICATES_ISSUER_GROUP_DATA
} from '../../../assets/data/admin-doc-source-data';
import { IssuerMethodsService } from './issuer-methods.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Service used to manage
 */
export class AdminDocSourceService {

  constructor(private issuerMethodsService: IssuerMethodsService) {
  }

  //region —— source step section with DATA ——

  /**
   * Building source step sections, w/ specific objects and methods.
   */
  getUpAdminDocSourceStepSectionFromScratch() {
    // Set issuerGroups map for admin_docs section
    const adminDocsIssuerGroups = new Map<string, AdminDocSourceIssuerGroupData>();

    adminDocsIssuerGroups.set('pref-and-mrae',
      new AdminDocSourceIssuerGroupData(
        DEFAULT_PREF_AND_MRAE_ISSUER_GROUP_DATA,
        true,
        this.issuerMethodsService.prefAndMraeTreeviewItems_adminDocSearch.map((item) => new TreeviewItem(item))
      )
    );
    adminDocsIssuerGroups.set('region-and-department',
      new AdminDocSourceIssuerGroupData(
        DEFAULT_REGION_AND_DEPARTMENT_ISSUER_GROUP_DATA,
        true,
        regionAndDepartmentTreeviewItems_adminDocSearch.map((item) => new TreeviewItem(item))
      )
    );
    adminDocsIssuerGroups.set('syndicates',
      new AdminDocSourceIssuerGroupData(
        DEFAULT_SYNDICATES_ISSUER_GROUP_DATA,
        true,
        []
      ));
    adminDocsIssuerGroups.set('comm-and-epci',
      new AdminDocSourceIssuerGroupData(
        DEFAULT_COMM_AND_EPCI_ISSUER_GROUP_DATA,
        true,
        commAndEpciTreeviewItems.map((item) => new TreeviewItem(item))
      )
    );
    return adminDocsIssuerGroups;

  }

}

<!--COMPONENT ONLY USED ON OVERVIEWSTEP COMPONENT-->
<a [routerLink]="allowOnClick ? docId : null"
   (contextmenu)="onClickCard()"
   (click)="onClickCard()"
   [state]="{context: text}">
  <div [ngClass]="{'preview': isPreview}" class="card-board">
    <div class="row">

      <div class="col-12 card-info d-flex color-basic-800 fw-700">
        <div class="d-flex align-self-center width-fit-content">
          <div class="icon icon--calendar"></div>
          <div *ngIf="publicationDate" class="text-capitalize">
            {{publicationDate | date:'dd MMMM yyyy':'':'fr' }}</div>
          <div *ngIf="!publicationDate" class="color-basic-600 text-capitalize">{{'content.date_not_detected' | translate}}</div>
        </div>
        <div class="publication-category d-flex width-fit-content align-self-center">
          <mat-icon *ngIf="(displayedPublicationType !== TerritoryWatchProjectPublicationTypeEnum.NON_DETECTED)"
                    class="fill-primary-500 icon-16 me-1 d-flex align-self-center"
                    svgIcon="docs-basic-600"></mat-icon>
          <mat-icon *ngIf="(displayedPublicationType === TerritoryWatchProjectPublicationTypeEnum.NON_DETECTED)"
                    svgIcon="docs-basic-600" class="icon-16 me-1 d-flex align-self-center"></mat-icon>
          <div [style.font-style]="(displayedPublicationType === TerritoryWatchProjectPublicationTypeEnum.NON_DETECTED) ? 'italic' : 'normal'"
               class="category-name d-flex align-self-center">{{displayedPublicationType}}</div>
        </div>
        <div class="d-flex align-self-center website-part">
          <mat-icon svgIcon="website" class="icon-16 fill-basic-600 me-1"></mat-icon>
          <div class="text-ellipsis">{{displayedUrl}}</div>
        </div>
      </div>

    </div>

    <div class="card-title position-static text-truncate" [innerHTML]="title && (title | articleTitleCase)"></div>

    <div class="territory-section"
         [ngClass]="displayReadMore ? 'split' : ''"
         id="territory-section-{{docId}}">
      <div *ngIf="territories && territories.length > 0"
           class="territories d-flex align-self-center">
        <mat-icon svgIcon="pin" class="icon-16 color-primary-500 me-1"></mat-icon>
        <div id="territory-list-{{docId}}" class="territory-list text-truncate-territories">
          <span *ngFor="let territory of territories; let i = index">
            <span class="territory-name fw-bold color-primary-500">{{territory.name}}</span>
            <span *ngIf="territories && i < territories.length - 1"
                  class="separator color-basic-600 fw-light"> | </span>
            <span *ngIf="territories && i === territories.length - 1" class="fantom-space">...</span>
          </span>
        </div>
      </div>
      <div *ngIf="territories?.length === 0" class="territories d-flex align-self-center">
        <mat-icon svgIcon="pin-basic-600" class="icon-16 fill-basic-600 me-1"></mat-icon>
        <span class="fst-italic territory-name fw-bold color-basic-600">{{'content.non-detected-territory' | translate}}</span>
      </div>
      <div *ngIf="displayReadMore" class="fading-color-box">

      </div>
      <div *ngIf="displayReadMore"
           id="read-more-{{docId}}"
           class="read-more d-flex"
           (click)="onReadMoreClick($event)"
           [ngbTooltip]="territoryTooltip"
           tooltipClass="custom-tooltip-article-card-territories"
           placement="bottom-right">
        <mat-icon svgIcon="plus-circle-icon-fill" class="icon-16 fill-basic-600 me-1 align-self-center align-middle"></mat-icon>
        <div class="text color-basic-600 fw-bold align-self-center">{{"content.see-more"| translate}}</div>
      </div>
    </div>

    <div *ngIf="inquirers && inquirers.length > 0" class="card-inquirers">
      <span class="inquirers fw-bold">{{'content.inquirers' | translate }}</span>
      <span class="text-ellipsis">{{inquirers}}</span>
    </div>
    <div *ngIf="!inquirers?.length" class="card-inquirers">
      <span class="inquirers fw-bold">{{'content.inquirers' | translate }}</span>
      <span class="fst-italic">{{'content.undetected-inquirers' | translate }}</span>
    </div>

    <div *ngIf="text" class="card-text" [innerHTML]="text"></div>
    <div *ngIf="!text" class="card-text fst-italic" [innerHTML]="'content.undetected-text' | translate"></div>

    <div *ngIf="nbNewDocuments" class="card-text fw-bold">
      <div *ngIf="nbNewDocuments === 1">{{nbNewDocuments}} {{'content.new-document' | translate}}</div>
      <div *ngIf="nbNewDocuments > 1">{{nbNewDocuments}} {{'content.new-documents' | translate}}</div>
    </div>

  </div>
</a>


<ng-template #territoryTooltip class="territory-tooltip">
      <span *ngFor="let territory of territories; let i = index">
            <span class="territory-name fw-bold color-white">{{territory.name}}</span>
            <span *ngIf="territories && i < territories.length - 1"
                  class="separator color-basic-600 fw-light"> | </span>
      </span>
</ng-template>
